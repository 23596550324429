// ** React Imports
import { lazy, Suspense } from 'react';
import ReactDOM from 'react-dom';

// ** PrismJS
import 'prismjs';
import 'prismjs/themes/prism-tomorrow.css';
import 'prismjs/components/prism-jsx.min';

// ** React Perfect Scrollbar
import 'react-perfect-scrollbar/dist/css/styles.css';

// ** React Toastify
import 'modules/core/styles/scss/react/libs/toastify/toastify.scss';

// ** Core styles
import 'modules/core/assets/fonts/feather/iconfont.css';
import 'modules/core/styles/scss/core.scss';
import 'modules/core/styles/index.scss';

// ** Service Worker
import * as serviceWorker from './serviceWorker';

// ** Sentry
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';

// ** Lazy load app
const LazyApp = lazy(() => import('./App'));

Sentry.init({
  environment: process.env.NODE_ENV,
  dsn: process.env.REACT_APP_SENTRY_DSN,
  integrations: [
    new BrowserTracing({
      tracingOrigins: [process.env.REACT_APP_BASE_API_URL],
    }),
  ],
  tracesSampleRate: 1.0,
});

ReactDOM.render(
  <Suspense fallback={null}>
    <LazyApp />
  </Suspense>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
